<template>
    <div class="vcTop">
        <!-- 头部区域 -->
        <div class="vctBox">
            <!-- 顶部5盒子 -->
            <div class="vctTop">
                <div class="vtLogo"></div>
                <div class="vtList">
                    <ul>
                        <li class="vtLi">
                            <img
                                src="https://img.alicdn.com/imgextra/i2/O1CN01ctFQ1k1u2QprOanof_!!6000000005979-2-tps-209-75.png"
                                alt=""
                                class="vlBg"
                            />
                            <div class="vlP">
                                <p class="vl1">数码家电</p>
                                <p class="vl2">每满300减50</p>
                            </div>
                            <img
                                src="https://img.alicdn.com/bao/uploaded/O1CN01hgwdDS25meWHhT3hg_!!6000000007569-0-yinhe.jpg_80x80q90.jpg_.webp"
                                alt=""
                                class="vlImg"
                            />
                        </li>
                        <li class="vtLi">
                            <img
                                src="https://img.alicdn.com/imgextra/i1/O1CN01gcWSWP1dP0tecUU36_!!6000000003727-2-tps-209-75.png"
                                alt=""
                                class="vlBg"
                            />
                            <div class="vlP">
                                <p class="vl1">智慧出行</p>
                                <p class="vl2">好物不只5折</p>
                            </div>
                            <img
                                src="https://img.alicdn.com/bao/uploaded/O1CN01woYUKH1aYE0SyOWIS_!!6000000003341-0-yinhe.jpg_80x80q90.jpg_.webp"
                                alt=""
                                class="vlImg"
                            />
                        </li>
                        <li class="vtLi">
                            <img
                                src="https://img.alicdn.com/imgextra/i2/O1CN011oU6gO1p8Jqj3LuqM_!!6000000005315-2-tps-209-75.png"
                                alt=""
                                class="vlBg"
                            />
                            <div class="vlP">
                                <p class="vl1">厨房餐饮</p>
                                <p class="vl2">大牌不只5折</p>
                            </div>
                            <img
                                src="https://img.alicdn.com/bao/uploaded/O1CN01GKiq431F1bsOfVpvm_!!6000000000427-0-yinhe.jpg_80x80q90.jpg_.webp"
                                alt=""
                                class="vlImg"
                            />
                        </li>
                        <li class="vtLi">
                            <img
                                src="https://img.alicdn.com/imgextra/i3/O1CN01jgEt4s1tk6zWwvw2A_!!6000000005939-2-tps-209-75.png"
                                alt=""
                                class="vlBg"
                            />
                            <div class="vlP">
                                <p class="vl1">大牌个护</p>
                                <p class="vl2">每满300减50</p>
                            </div>
                            <img
                                src="https://img.alicdn.com/bao/uploaded/O1CN01s7YJuS1oni0umW2ce_!!6000000005270-0-yinhe.jpg_80x80q90.jpg_.webp"
                                alt=""
                                class="vlImg"
                            />
                        </li>
                        <li class="vtLi">
                            <img
                                src="https://img.alicdn.com/imgextra/i2/O1CN01cF2FzY1o6enKUGguq_!!6000000005176-2-tps-209-75.png"
                                alt=""
                                class="vlBg"
                            />
                            <div class="vlP">
                                <p class="vl1">品质生鲜</p>
                                <p class="vl2">抢卷300减60</p>
                            </div>
                            <img
                                src="https://img.alicdn.com/bao/uploaded/O1CN01wUCAmp29MKDtuXprr_!!6000000008053-0-yinhe.jpg_80x80q90.jpg_.webp"
                                alt=""
                                class="vlImg"
                            />
                        </li>
                    </ul>
                </div>
            </div>
            <!-- 搜索区域 -->
            <div class="vctSearchs">
                <div class="vsBox">
                    <div class="vsLeft"></div>
                    <div class="vsCenter">
                        <div class="vscSearch">
                            <input class="vsInp" type="search" placeholder="小白鞋" />
                            <button class="vsBtn" @click="goDetail()">搜索</button>
                        </div>
                        <div class="vsHot">
                            <ul>
                                <li class="vhLi" @click="goDetail2(7)">404</li>
                                <li class="vhLi" @click="goDetail2(8)">暂无数据</li>
                                <li class="vhLi" @click="goDetail2(9)">系统安装</li>
                                <li class="vhLi" @click="goDetail2(10)">SSL证书</li>
                                <li class="vhLi" @click="goDetail2(11)">威有云详情页</li>
                                <li class="vhLi" @click="goDetail2(12)">域名详情页</li>
                                <li class="vhLi" @click="goDetail2(13)">想要域名页</li>
                                <li class="vhLi" @click="goDetail2(14)">威有云详情页</li>
                            </ul>
                        </div>
                    </div>
                    <div class="vsRight">
                        <div class="vrCode">
                            <p class="vcP">威有商城</p>
                            <img :src="getWebData().shop_qrcode" alt="" class="vcImg" />
                        </div>
                    </div>
                </div>
            </div>
            <!-- 主体区域 -->
            <div class="vctBody">
                <!-- 左侧导航 -->
                <div class="vbLeft">
                    <div class="vblTop">分类</div>
                    <div class="vblList">
                        <ul>
                            <li class="vblLi">
                                <i class="iconfont icon-nvshi"></i>
                                <a href="">女装 </a>/ <a href="">内衣 </a>/
                                <a href="">奢品</a>
                                <!-- 右侧菜单 -->
                                <div class="vlRightBox" @mousewheel.prevent>
                                    <div class="vrBox">
                                        <div class="vrbTop">
                                            <dl class="vbtDl">
                                                <dt class="vbtDt" v-for="item in 3" :key="item">家电馆</dt>
                                            </dl>
                                        </div>
                                        <div class="vrbList">
                                            <dl class="vblDl">
                                                <dt class="vblDt" v-for="item in 5" :key="item">
                                                    <div class="vl1">
                                                        <a href="#">电视 ></a>
                                                    </div>
                                                    <div class="vl2">
                                                        <div class="vlDts" v-for="item in 5" :key="item">
                                                            <a href="#">全面屏电视</a>
                                                        </div>
                                                        <div class="vlDts" v-for="item in 6" :key="item">
                                                            <a href="#">OLED电视</a>
                                                        </div>
                                                    </div>
                                                </dt>
                                            </dl>
                                        </div>
                                    </div>
                                    <!-- 右侧图文 -->
                                    <div class="vrbImgs">
                                        <ul>
                                            <li class="viLi">
                                                <a href="#">
                                                    <p class="vilP1">威有海风统一门户</p>
                                                    <p class="vilP2">企业级数字化</p>
                                                    <p class="vilP2">帮助企业提升业务效率</p></a
                                                >
                                            </li>
                                            <li class="viLi">
                                                <a href="#">
                                                    <p class="vilP1">H5响应式官网定制</p>
                                                    <p class="vilP2">支持多搜索引擎</p>
                                                    <p class="vilP2">资深设计师一对一量身...</p></a
                                                >
                                            </li>
                                            <li class="viLi">
                                                <a href="#">
                                                    <p class="vilP1">响应式商城网站</p>
                                                    <p class="vilP2">打造商家自有电商平台</p>
                                                    <p class="vilP2">降低网上开店成本</p></a
                                                >
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                            <li class="vblLi" v-for="item in 7" :key="item">
                                <i class="iconfont icon-nvshi"></i>
                                <a href="">女装 </a>/ <a href="">内衣 </a>/
                                <a href="">奢品</a>
                                <!-- 右侧菜单 -->
                                <div class="vlRightBox" @mousewheel.prevent>
                                    <div class="vrBox">
                                        <div class="vrbTop">
                                            <dl class="vbtDl">
                                                <dt class="vbtDt" v-for="item in 3" :key="item">家电馆</dt>
                                            </dl>
                                        </div>
                                        <div class="vrbList">
                                            <dl class="vblDl">
                                                <dt class="vblDt" v-for="item in 7" :key="item">
                                                    <div class="vl1">
                                                        <a href="#">电视 ></a>
                                                    </div>
                                                    <div class="vl2">
                                                        <div class="vlDts" v-for="item in 5" :key="item">
                                                            <a href="#">全面屏电视</a>
                                                        </div>
                                                        <div class="vlDts" v-for="item in 6" :key="item">
                                                            <a href="#">OLED电视</a>
                                                        </div>
                                                    </div>
                                                </dt>
                                            </dl>
                                        </div>
                                    </div>
                                    <!-- 右侧图文 -->
                                    <div class="vrbImgs">
                                        <ul>
                                            <li class="viLi">
                                                <a href="#">
                                                    <p class="vilP1">威有海风统一门户</p>
                                                    <p class="vilP2">企业级数字化</p>
                                                    <p class="vilP2">帮助企业提升业务效率</p></a
                                                >
                                            </li>
                                            <li class="viLi">
                                                <a href="#">
                                                    <p class="vilP1">H5响应式官网定制</p>
                                                    <p class="vilP2">支持多搜索引擎</p>
                                                    <p class="vilP2">资深设计师一对一量身...</p></a
                                                >
                                            </li>
                                            <li class="viLi">
                                                <a href="#">
                                                    <p class="vilP1">响应式商城网站</p>
                                                    <p class="vilP2">打造商家自有电商平台</p>
                                                    <p class="vilP2">降低网上开店成本</p></a
                                                >
                                            </li>
                                        </ul>
                                    </div>
                                    <!-- 左侧文字列表 -->
                                    <!-- <div class="vrbTexts">
                    <ul>
                      <li class="vtLi" v-for="item in 18" :key="item">
                        <p class="vtl1">企业官网</p>
                        <p class="vtl2">提供智能建站平台和企业建设</p>
                      </li>
                    </ul>
                  </div> -->
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- 中间图片 -->
                <div class="vbCenter">
                    <div class="vbcTop">
                        <el-carousel height="340px">
                            <el-carousel-item v-for="item in 4" :key="item">
                                <img src="http://iv.vu818.com/img/test1.jpg" alt="" class="vtImg" />
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                    <div class="vbcBottom">
                        <div class="vbBox">
                            <div class="vbbP">
                                <p class="vp1">小程序专区</p>
                                <p class="vp2">覆盖各行业赛道的小程序模板</p>
                            </div>
                            <img src="http://iv.vu818.com/img/test2.png" alt="" class="vbbImg" />
                        </div>
                        <div class="vbBox">
                            <div class="vbbP">
                                <p class="vp1">实时掌握生产进度</p>
                                <p class="vp2">每年千元，告别客户订单延期</p>
                            </div>
                            <img src="http://iv.vu818.com/img/test3.jpg" alt="" class="vbbImg" />
                        </div>
                    </div>
                </div>
                <!-- 右边列表 -->
                <div class="vbRight">
                    <ul>
                        <li class="vbrLi" @click="goDetail2(1)">
                            <div class="vbrBox1">想要域名专区</div>
                            <div class="vbrBox2">想要域名专区</div>
                        </li>
                        <li class="vbrLi" @click="goDetail2(2)">
                            <div class="vbrBox1">工商专区</div>
                            <div class="vbrBox2">工商专区</div>
                        </li>
                        <li class="vbrLi" @click="goDetail2(3)">
                            <div class="vbrBox1">工商详情页</div>
                            <div class="vbrBox2">工商详情页</div>
                        </li>
                        <li class="vbrLi" @click="goDetail2(4)">
                            <div class="vbrBox1">商标注册专区</div>
                            <div class="vbrBox2">商标注册专区</div>
                        </li>
                        <li class="vbrLi" @click="goDetail2(5)">
                            <div class="vbrBox1">想要商标</div>
                            <div class="vbrBox2">想要商标</div>
                        </li>
                        <li class="vbrLi" @click="goDetail2(6)">
                            <div class="vbrBox1">域名备案</div>
                            <div class="vbrBox2">域名备案</div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'VcTop',
    inject: ['getWebData'],
    methods: {
        goDetail() {
            this.$router.push('/vyListP');
        },
        goDetail2(i) {
            switch (i) {
                case 1:
                    this.$router.push('/domain');
                    break;
                case 2:
                    this.$router.push('/business');
                    break;
                case 3:
                    this.$router.push('/tbusinDetail');
                    break;
                case 4:
                    this.$router.push('/trademark');
                    break;
                case 5:
                    this.$router.push('/tmSale');
                    break;
                case 6:
                    this.$router.push('/dmRecord');
                    break;
                case 7:
                    this.$router.push('/the404');
                    break;
                case 8:
                    this.$router.push('/theNot');
                    break;
                case 9:
                    this.$router.push('/theSystem');
                    break;
                case 10:
                    this.$router.push('/theSsl');
                    break;
                case 11:
                    this.$router.push('/vyListp');
                    break;
                case 12:
                    this.$router.push('/dmDetail');
                    break;
                case 13:
                    this.$router.push('/dmSale');
                    break;
                case 14:
                    this.$router.push('/vyDetail');
                    break;
            }
        },
    },
};
</script>

<style lang="less" scoped>
.vcTop {
    min-width: 1423px;
    height: 941px;
    .vctBox {
        width: 1280px;
        margin: 0 auto;
        padding-top: 87px;
        // 顶部5盒子
        .vctTop {
            width: 100%;
            height: 100px;
            .vtLogo {
                width: 100px;
                height: 100px;
                float: left;
            }
            .vtList {
                width: 1100px;
                height: 100px;
                margin: 0 auto;
                float: left;
                ul {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: space-around;
                    .vtLi {
                        width: 19%;
                        height: 80%;
                        margin-top: 12px;
                        position: relative;
                        .vlBg {
                            width: 100%;
                            height: 100%;
                        }
                        .vlP {
                            width: 100px;
                            height: 50px;
                            position: absolute;
                            top: 15px;
                            left: 20px;
                            color: white;
                            .vl1 {
                                width: 100px;
                                height: 30px;
                                line-height: 30px;
                                font-size: 20px;
                            }
                            .vl2 {
                                width: 100px;
                                height: 20px;
                                line-height: 20px;
                                font-size: 12px;
                            }
                        }
                        .vlImg {
                            width: 52px;
                            height: 52px;
                            position: absolute;
                            top: 12px;
                            right: 15px;
                        }
                    }
                }
            }
        }
        // 搜索区域
        .vctSearchs {
            width: 100%;
            height: 120px;
            border-top-left-radius: 40px;
            border-top-right-radius: 40px;
            background-color: white;
            .vsBox {
                width: 90%;
                height: 100%;
                margin: 0 auto;
                padding-top: 20px;
                box-sizing: border-box;
                .vsLeft {
                    width: 15%;
                    height: 60px;
                    float: left;
                }
                .vsCenter {
                    width: 63%;
                    height: 80px;
                    float: left;
                    margin-left: 50px;
                    .vscSearch {
                        width: 100%;
                        height: 40px;
                        border: 2px solid #a872fe;
                        background-color: #fff;
                        border-radius: 20px;
                        .vsInp {
                            width: 80%;
                            height: 99%;
                            margin-left: 20px;
                            outline: none;
                            border: none;
                            font-size: 15px;
                        }
                        .vsBtn {
                            width: 85px;
                            height: 36px;
                            border: none;
                            color: white;
                            font-size: 18px;
                            float: right;
                            margin-right: 2px;
                            margin-top: 2px;
                            background-color: #a872fe;
                            border-radius: 20px;
                            cursor: pointer;
                        }
                    }
                    .vsHot {
                        width: 80%;
                        height: 30px;
                        ul {
                            width: 100%;
                            height: 100%;
                            display: flex;
                            justify-content: space-around;
                            .vhLi {
                                min-width: 24px;
                                height: 30px;
                                line-height: 30px;
                                font-size: 15px;
                                cursor: pointer;
                            }
                            .vhLi:hover {
                                color: #a872fe;
                            }
                        }
                    }
                }
                .vsRight {
                    width: 12%;
                    height: 95px;
                    float: left;
                    margin-left: 50px;
                    .vrCode {
                        width: 72px;
                        height: 90px;
                        margin-left: 25px;
                        background-color: #fff;
                        box-shadow: 1px 1px 10px 1px gainsboro;
                        text-align: center;
                        border-radius: 8px;
                        .vcP {
                            width: 100%;
                            height: 20px;
                            line-height: 20px;
                            font-size: 14px;
                        }
                        .vcImg {
                            border-radius: 8px;
                            width: 70px;
                            height: 70px;
                        }
                    }
                }
            }
        }
        // 主体区域
        .vctBody {
            width: 100%;
            height: 550px;
            position: relative;
            background-color: white;
            border-bottom-left-radius: 40px;
            border-bottom-right-radius: 40px;
            // 左侧导航
            .vbLeft {
                width: 16%;
                height: 500px;
                margin-left: 15px;
                float: left;
                background-color: white;
                box-shadow: 1px 1px 10px 1px gainsboro;
                border-radius: 20px;
                .vblTop {
                    width: 100%;
                    height: 40px;
                    line-height: 40px;
                    padding-left: 20px;
                    box-sizing: border-box;
                    font-size: 20px;
                    background-color: #eee;
                    border-top-left-radius: 20px;
                    border-top-right-radius: 20px;
                }
                .vblList {
                    width: 100%;
                    height: 460px;
                    transition: 0.5s;
                    ul {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: space-around;
                        flex-direction: column;
                        .vblLi {
                            width: 100%;
                            height: 50px;
                            line-height: 50px;
                            padding-left: 5px;
                            a {
                                color: black;
                            }
                            .icon-nvshi {
                                font-size: 25px;
                                padding-right: 20px;
                            }
                            a:hover {
                                text-decoration: underline;
                            }
                            // 右侧菜单
                            .vlRightBox {
                                width: 930px;
                                min-height: 500px;
                                background-color: white;
                                box-shadow: 1px 1px 10px 1px gainsboro;
                                position: absolute;
                                top: 0px;
                                left: 17%;
                                border-top-right-radius: 20px;
                                border-bottom-right-radius: 20px;
                                display: none;
                                transition: 0.8s;
                                z-index: 999;
                                //  商品列表
                                .vrBox {
                                    width: 600px;
                                    height: 95%;
                                    margin-left: 20px;
                                    margin-top: 15px;
                                    float: left;
                                    // overflow-y: auto;
                                    .vrbTop {
                                        width: 100%;
                                        height: 25px;
                                        .vbtDl {
                                            width: 100%;
                                            height: 100%;
                                            .vbtDt {
                                                min-width: 73px;
                                                height: 25px;
                                                line-height: 25px;
                                                font-size: 14px;
                                                text-align: center;
                                                border-radius: 5px;
                                                color: white;
                                                background-color: black;
                                                float: left;
                                                margin-left: 10px;
                                                cursor: pointer;
                                            }
                                            .vbtDt:hover {
                                                background-color: #7781f1;
                                            }
                                        }
                                    }
                                    .vrbList {
                                        width: 100%;
                                        margin-top: 20px;
                                        .vblDl {
                                            width: 100%;
                                            height: 100%;
                                            display: flex;
                                            justify-content: space-around;
                                            flex-direction: column;
                                            font-size: 14px;
                                            .vblDt {
                                                width: 100%;
                                                min-height: 32px;
                                                .vl1 {
                                                    width: 8%;
                                                    height: 32px;
                                                    line-height: 32px;
                                                    font-weight: 700;
                                                    float: left;
                                                    text-align: center;
                                                }
                                                .vl2 {
                                                    width: 92%;
                                                    min-height: 32px;
                                                    float: left;
                                                    .vlDts {
                                                        min-width: 38px;
                                                        height: 32px;
                                                        line-height: 32px;
                                                        text-align: center;
                                                        float: left;
                                                        color: gray;
                                                        margin-left: 10px;
                                                    }
                                                }
                                                a {
                                                    color: black;
                                                }
                                                a:hover {
                                                    color: #7781f1;
                                                    text-decoration: none;
                                                }
                                            }
                                        }
                                    }
                                }
                                // 右侧图文
                                .vrbImgs {
                                    width: 280px;
                                    height: 450px;
                                    margin-top: 25px;
                                    margin-left: 20px;
                                    float: left;
                                    ul {
                                        width: 100%;
                                        height: 100%;
                                        display: flex;
                                        justify-content: space-around;
                                        .viLi {
                                            width: 250px;
                                            height: 130px;
                                            margin-left: 15px;
                                            border-radius: 10px;
                                            a {
                                                color: black;
                                                text-decoration: none;
                                            }
                                            .vilP1 {
                                                width: 150px;
                                                height: 30px;
                                                line-height: 30px;
                                                font-size: 15px;
                                                margin-top: 20px;
                                                margin-left: 10px;
                                            }
                                            .vilP2 {
                                                width: 150px;
                                                height: 20px;
                                                font-size: 12px;
                                                color: gray;
                                                margin-left: 10px;
                                            }
                                        }
                                        .viLi:nth-child(1) {
                                            background: url('https://imgcache.qq.com/open_proj/proj_qcloud_v2/marketPlace/build/images/20220328-home-detailBg1.jpg')
                                                right no-repeat;
                                        }
                                        .viLi:nth-child(2) {
                                            background: url('https://imgcache.qq.com/open_proj/proj_qcloud_v2/marketPlace/build/images/20220328-home-detailBg2.jpg')
                                                right no-repeat;
                                        }
                                        .viLi:nth-child(3) {
                                            background: url('https://imgcache.qq.com/open_proj/proj_qcloud_v2/marketPlace/build/images/20220328-home-detailBg3.jpg')
                                                right no-repeat;
                                        }
                                    }
                                }
                                ::-webkit-scrollbar {
                                    display: none;
                                }
                                // 左侧文字列表
                                // .vrbTexts {
                                //   width: 600px;
                                //   float: left;
                                //   max-height: 460px;
                                //   margin-top: 15px;
                                //   margin-left: 20px;
                                //   overflow-y: auto;
                                //   ul {
                                //     width: 100%;
                                //     height: 100%;
                                //     display: flex;
                                //     flex-wrap: wrap;
                                //     flex-direction: row;
                                //     .vtLi {
                                //       width: 40%;
                                //       height: 50px;
                                //       margin-top: 20px;
                                //       .vtl1 {
                                //         width: 100%;
                                //         height: 30px;
                                //         line-height: 30px;
                                //         font-size: 15px;
                                //         padding-left: 5px;
                                //       }
                                //       .vtl2 {
                                //         width: 100%;
                                //         height: 20px;
                                //         line-height: 20px;
                                //         font-size: 12px;
                                //         color: gray;
                                //         padding-left: 5px;
                                //       }
                                //     }
                                //     .vtLi:hover {
                                //       box-shadow: 1px 1px 10px 1px gainsboro;
                                //       .vtl1 {
                                //         color: #a872fe;
                                //       }
                                //     }
                                //   }
                                // }
                            }
                        }
                        .vblLi:hover {
                            box-shadow: 1px 1px 10px 1px gainsboro;
                            border-radius: 10px;
                            a {
                                color: #a872fe;
                            }
                            .vlRightBox {
                                display: block;
                            }
                        }
                    }
                }
            }
            // 中间图片
            .vbCenter {
                width: 49%;
                height: 500px;
                float: left;
                margin-left: 15px;
                .vbcTop {
                    width: 100%;
                    height: 340px;
                    border-radius: 20px;
                    .vtImg {
                        width: 100%;
                        height: 100%;
                        border-radius: 20px;
                    }
                }
                .vbcBottom {
                    width: 100%;
                    height: 130px;
                    margin-top: 30px;
                    .vbBox {
                        width: 49%;
                        height: 100%;
                        float: left;
                        border-radius: 10px;
                        position: relative;
                        transition: 0.8s;
                        .vbbP {
                            width: 200px;
                            height: 50px;
                            position: absolute;
                            top: 40px;
                            left: 10px;
                            color: white;
                            .vp1 {
                                width: 100%;
                                height: 30px;
                                line-height: 30px;
                                font-size: 15px;
                            }
                            .vp2 {
                                width: 100%;
                                height: 20px;
                                line-height: 20px;
                                font-size: 12px;
                            }
                        }
                        .vbbImg {
                            width: 100%;
                            height: 100%;
                            border-radius: 10px;
                        }
                    }
                    .vbBox:nth-child(2) {
                        margin-left: 2%;
                    }
                    .vbBox:hover {
                        box-shadow: 5px 5px 10px 5px gainsboro;
                    }
                }
            }
            // 右边列表
            .vbRight {
                width: 31%;
                height: 500px;
                float: left;
                margin-left: 15px;
                ul {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-around;
                    .vbrLi {
                        width: 47%;
                        height: 130px;
                        background: url('https://imgcache.qq.com/open_proj/proj_qcloud_v2/marketPlace/build/images/mpm200829-thumbnailImg2.jpg')
                            no-repeat;
                        border-radius: 10px;
                        transition: 0.5s;
                        cursor: pointer;
                        .vbrBox1 {
                            width: 80%;
                            height: 40px;
                            line-height: 40px;
                            margin: 0 auto;
                            margin-top: 30px;
                            text-align: center;
                            color: #006eff;
                            font-weight: 900;
                        }
                        .vbrBox2 {
                            width: 100%;
                            height: 40px;
                            line-height: 40px;
                            margin-top: 20px;
                            font-size: 15px;
                            text-align: center;
                            background-color: #fff;
                            border-bottom-left-radius: 10px;
                            border-bottom-right-radius: 10px;
                        }
                    }
                    .vbrLi:hover {
                        box-shadow: 1px 1px 10px 1px gainsboro;
                    }
                }
            }
        }
    }
}
</style>
